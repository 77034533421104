import React from 'react';
import "./contact.css";
import mapImg from "../../assests/map.png";
const Contact = () => {
    return (
        <div>
            <div id='contact' className="container" style={{padding:"90px 0"  }}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <span className="text-white-50 lead">
                            You can contact us from here in case of any
                            inquiries
                        </span>
                        <ul className="list-unstyled pt-3">
                            <li className="d-flex gap-2 py-3 pr-3 text-white align-items-center  border-1 border-white border-bottom">
                                <span className="icon-contact fs-4"><i className="fa-brands fa-whatsapp"></i></span>
                                <label>Whatsapp</label>
                                <span>0097059848236</span>
                            </li>
                            <li className="d-flex gap-2 py-3 pr-3 text-white align-items-center  border-1 border-white border-bottom">
                                <span className="icon-contact fs-4"><i className="fa-solid fa-phone"></i></span>
                                <label>Whatsapp</label>
                                <span>0097059848236</span>
                            </li>
                            <li className="d-flex gap-2 py-3 pr-3 text-white align-items-center  border-1 border-white border-bottom">
                                <span className="icon-contact fs-4"><i className="fa-solid fa-at"></i></span>
                                <label>Whatsapp</label>
                                <span>0097059848236</span>
                            </li>
                        </ul>
                        <div className="time-open py-4">
                            <span className="text-white-50">Working hours <b>08:00 - 12:00 </b> </span><br />
                                <span className="text-white-50">All days of the week except Friday</span>
                        </div>
                        <div className="pt-4 pr-4 pb-4">
                            <span className="text-white">CopyRight The House GYM 2000-2022</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="w-100 d-none d-lg-inline-block">
                            <img className="w-100 img-fluid img-thumbnail" src={mapImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
