import React from 'react';
import "./coache.css";
import coacheImg from "../../assests/young-sports-people-training-morning-gym.jpg";
const Coache = () => {
    return (
        <div>
            <div id='coache' className="our-Coache position-relative overflow-hidden  py-5" >
                <div className="container bg-white">
                    <div className="pt-4">
                        <h1 className="fs-2 text-dark fw-light">Get To Know <span className="head-coache-mian text-white px-2 py-4">The Coaches</span>  </h1>
                    </div>
                    <div className="row my-5 pb-5">
                        <div className="col-12 col-md-6">
                            <ul className="list-group  list-group-flush">
                                <li className="list-group-item">
                                    <label className="fw-bolder">Coache:</label>
                                    <span className="opacity-75 ps-1">Alex D Marshil</span>
                                </li>
                                <li className="list-group-item">
                                    <label className="fw-bolder">Expertise:</label>
                                    <span className="opacity-75 ps-1">3 Years Expertise</span>
                                </li>
                                <li className="list-group-item">
                                    <label className="fw-bolder">Age:</label>
                                    <span className="opacity-75 ps-1">27 years</span>
                                </li>
                                <li className="list-group-item">
                                    <label className="fw-bolder">Availability Times:</label>
                                    <span className="opacity-75 ps-1">Everyday from 10:00 PM to 12:00 AM</span>
                                </li>
                                <li className="list-group-item">
                                    <label className="fw-bolder">Long:</label>
                                    <span className="opacity-75 ps-1">175 cm</span>
                                </li>
                                <li className="list-group-item">
                                    <label className="fw-bolder">Email:</label>
                                    <span className="opacity-75 ps-1">alex@gmail.com</span>
                                </li>
                            </ul>

                            <ul className="list-coche-social list-unstyled list-inline text-dark mt-5 float-end">
                                <li className="list-inline-item">
                                    <span className="fs-3 p-3"><i className="fa-brands fa-instagram"></i></span>
                                </li>
                                <li className="list-inline-item">
                                    <span className="fs-3 p-3"><i className="fa-brands fa-facebook-messenger"></i></span>
                                </li>
                                <li className="list-inline-item">
                                    <span className="fs-3 p-3"><i className="fa-brands fa-snapchat"></i></span>
                                </li>
                                <li className="list-inline-item">
                                    <span className="fs-3 p-3"><i className="fa-brands fa-whatsapp"></i></span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 ps-5">
                            <div className="row">
                                <div className="col-1">
                                    <div className="arrow-coache d-flex flex-column h-100 justify-content-between">
                                        <span className=" fs-3"><i className="fa-solid fa-arrow-up"></i></span>
                                        <span className=" fs-3"><i className="fa-solid fa-arrow-down"></i></span>
                                    </div>
                                </div>
                                <div className="col-11">
                                    <div className="w-100 position-relative" style={{height:"350px"}} >
                                        <span className="box-img-one position-absolute"></span>
                                        <span className="box-img-tow position-absolute"></span>
                                        <img className="w-100 h-100 px-5 position-absolute" style={{objectFit: "cover",zIndex: "9"}} src={coacheImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Coache;
