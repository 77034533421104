import React , {useState} from 'react';
import "./nav.css";
import { NavLink} from "react-router-dom";
import { Link } from 'react-scroll';
import { motion } from "framer-motion";

const Nav = () => {
    const [fix , setFix] = useState(false);
    function setFexid(){
        if(window.scrollY >= 100){
            setFix(true)
        }else{
            setFix(false)
        }
    }
    window.addEventListener("scroll" , setFexid);
    return (
        <div>
            <nav className="navbar navbar-expand-md pt-md-3 pt-xl-4 ">
                <div className={fix? "fixTrue":"fixFalse" }>
                    <button className="navbar-toggler bg-white mt-3 mt-md-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 mt-2 md:mt-0 md:p-0 p-3 ">
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-light" aria-current="page" spy={true} smooth={true} offset={0} duration={300} to="home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-ligh" spy={true} smooth={true} offset={0} duration={300} to="health">Health  Body</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-light" spy={true} smooth={true} offset={0} duration={300} to="choose">Why Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-light" spy={true} smooth={true} offset={0} duration={300} to="program">Programs</Link>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white fw-light" to="life">Life Club</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white fw-light" to="plain">Plans</NavLink>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-light" spy={true} smooth={true} offset={0} duration={300} to="coache">Coaches</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white fw-light" spy={true} smooth={true} offset={0} duration={300} to="contact">Contact</Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Nav;
