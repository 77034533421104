import React from 'react';
import "./program.css";
import programImg from "../../assests/endorphins-during-jogging-with-girlfriend.jpg";

const Program = () => {
    return (
        <div>
            <div id='program' className="our-programs  position-relative overflow-hidden  py-5" >
                <div className="container">
                    <div className="head-program">
                        <h1 className="fs-3 text-white">Some <span className="pop-head-programe p-1 d-inline-block position-relative">Programs</span> </h1>
                    </div>
                    <div className="row my-5">
                        <div className="col-12 col-md-5">
                            <div className="cards-programs">
                                <div className="position-relative  mb-4">
                                    <span className="rounded-circle fs-3 d-flex justify-content-center align-items-center text-white"><i className=" fa-solid fa-dumbbell"></i></span>
                                    <h5>Cards Programs</h5>
                                    <p className="opacity-75" >In this program, you are trained to improve your strength through many exercises.</p>
                                </div>
                                <div className="position-relative mb-4">
                                    <span className="rounded-circle fs-3 d-flex justify-content-center align-items-center text-white"><i className=" fa-solid fa-dumbbell"></i></span>
                                    <h5>Cards Programs</h5>
                                    <p className="opacity-75" >In this program, you are trained to improve your strength through many exercises.</p>
                                </div>
                                <div className="position-relative mb-4">
                                    <span className="rounded-circle fs-3 d-flex justify-content-center align-items-center text-white"><i className=" fa-solid fa-dumbbell"></i></span>
                                    <h5>Cards Programs</h5>
                                    <p className="opacity-75" >In this program, you are trained to improve your strength through many exercises.</p>
                                </div>
                                <div className="position-relative mb-4 d-inline-block d-md-none d-lg-inline-block">
                                    <span className="rounded-circle fs-3 d-flex justify-content-center align-items-center text-white"><i className=" fa-solid fa-dumbbell"></i></span>
                                    <h5>Cards Programs</h5>
                                    <p className="opacity-75" >In this program, you are trained to improve your strength through many exercises.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 ps-md-5">
                            <div className="w-100">
                                <img className="w-100 img-fluid img-thumbnail" src={programImg} alt="" />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mt-5">
                                        <span className="circle-programe d-block rounded-circle" ></span>
                                        <h5 className="head-subprograme text-white py-4" >Personal Training</h5>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mt-5">
                                        <span className="circle-programe d-block rounded-circle" ></span>
                                        <h5 className="head-subprograme text-white py-4" >Group Training</h5>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mt-5">
                                        <span className="circle-programe d-block rounded-circle" ></span>
                                        <h5 className="head-subprograme text-white py-4" >Private Training</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Program;
