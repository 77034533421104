import React from 'react';
import "./hero.css";
import three from "../../assests/cotch/three.png";
import tow from "../../assests/cotch/tow.png";
import seven from "../../assests/cotch/seven.png";
import six from "../../assests/cotch/six.png";
import one from "../../assests/cotch/one.png";
import four from "../../assests/cotch/four.png";
import logo from "../../logo.png";
import {motion} from "framer-motion";
import Nav from '../nav/nav';


const Hero = () => {
    return (
        <div>
            <div id='home' className="hero-photo overflow-hidden position-relative">
                {/* Hero Images */}
                <div className="container">
                    <div className="background-hero-image row row-cols-6">
                        <motion.div 
                        initial={{y:"100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}}
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:1}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={one} alt="" /> 
                        </motion.div>
                        <motion.div
                        initial={{y:"-100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}} 
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:0.8}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={tow} alt="" />
                        </motion.div>
                        <motion.div
                        initial={{y:"100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}} 
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:0.4}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={seven} alt="" />
                        </motion.div>
                        <motion.div
                        initial={{y:"-100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}} 
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:0.4}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={six} alt="" />
                        </motion.div>
                        <motion.div
                        initial={{y:"100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}} 
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:0.8}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={four} alt="" />
                        </motion.div>
                        <motion.div
                        initial={{y:"-100vh"}}
                        animate={{y:0}}
                        transition={{duration:0.3}} 
                        className="col card-hero position-relative d-flex justify-content-center align-items-center">
                            <motion.span 
                            initial={{scaleY:0}}
                            animate={{scaleY:1}}
                            transition={{duration:0.2 , delay:1}}
                            className="bg-blur position-absolute z-10 w-100 top-0 left-0 right-0 bottom-0"></motion.span>
                            <img className="w-100 position-relative z-20 my-auto h-75" src={three} alt="" />
                        </motion.div>
                    </div>  
                </div>
                {/* Main Home */}
                <motion.div 
                initial={{scale:0}}
                animate={{scale:1}}
                transition={{delay:1.5}}
                className="main-home overflow-hidden position-absolute w-100 top-0 left-0 right-0 bottom-0">
                    <div className="container" style={{height:"100vh"}}>
                         {/* navbar  */}
                        <div className="h-25">
                            <Nav />
                        </div>
                        {/* logo  */}
                        <div className="h-50 position-relative">
                            <h1 className="hero-logo text-center w-100 position-absolute top-50 start-50 translate-middle fw-lighter text-white">
                            <motion.span 
                            initial={{x:"-100vw"}}
                            animate={{x:0}}
                            transition={{delay:1.8}}
                            className="pe-3 d-inline-block">Welcome To The </motion.span>
                            
                            <motion.div
                            initial={{x:"100vw"}}
                            animate={{x:0}}
                            transition={{delay:1.8}}
                            >
                                <span className="name-club position-relative">
                                House Club
                                <label className="position-absolute"></label>
                                </span>
                                <img src={logo} alt="" />
                            </motion.div>  
                            </h1>
                        </div>
                         {/* info  */}
                        <div className="h-25">
                            <div className="row text-white pt-4">
                                <div className="col-3">
                                    <span className="fs-1 fw-lighter">+ 40</span><br />
                                    <span className="fw-lighter opacity-50">EXPERT COACHES</span>
                                </div>
                                <div className="col-3">
                                    <span className="fs-1 fw-lighter">+ 650</span><br />
                                    <span className="fw-lighter opacity-50">MEMBERS JOINED</span>
                                </div>
                                <div className="col-3">
                                    <span className="fs-1 fw-lighter">+ 60</span><br />
                                    <span className="fw-lighter opacity-50">FITNESS PROGRAMS</span>
                                </div>
                                <div className="col-3 ml-auto position-relative">
                                    <button className="lets-start border-0 position-absolute text-white"> <span className="d-none d-lg-inline-block">Let's Start The First Step</span> <span className="d-sm-inline-block d-md-inline-block d-none d-lg-none">Lets Go</span> <span className="px-4 px-sm-2"><i className="fa-solid fa-arrow-down"></i></span></button>
                                </div>
                            </div>  
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default Hero;
