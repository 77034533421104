import React from 'react';
import "./health.css";
import mainImg from "../../assests/cotch/seven.png";
const Health = () => {
    return (
        <div>
            <div id='health' className="container pt-4 my-5 bg-white">
                <h1 className="head-health-body fs-2 te fw-light">Your <b>Health</b>  And <b>Body</b>  Is What People See, <span>Make It Perfect</span> </h1>
                <div className="head-health-body-contant position-relative">
                    <div className="d-none d-md-inline-block position-absolute top-50 start-50 translate-middle ">
                        <img className="main-image-health-body img-fluid " src={mainImg} alt="" />
                    </div>
                    <div className="w-100 h-100 row">
                        <div className="col-lg-4 col-md-6">
                            <div className="card-health-body p-4">
                                <div className="bg-white position-relative">
                                    <div className="position-absolute h-100  p-3 d-flex flex-column " style={{zIndex: 50}}>
                                        <h1 className="fs-4">Health Fitness</h1>
                                        <p >
                                            This programs is designed
                                            for those who exercises only
                                            for their body fitness not body
                                            building.
                                        </p>
                                        <button className="btn btn-success mt-auto">Join Now</button>
                                    </div>
                                    <span className="position-absolute"><i className="fa-solid fa-heart-circle-check"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-4">
                            <div className="card-health-body p-4">
                                <div className="bg-white position-relative">
                                    <div className="position-absolute h-100  p-3 d-flex flex-column " style={{zIndex: 50}}>
                                        <h1 className="fs-4">Health Fitness</h1>
                                        <p >
                                            This programs is designed
                                            for those who exercises only
                                            for their body fitness not body
                                            building.
                                        </p>
                                        <button className="btn btn-success mt-auto">Join Now</button>
                                    </div>
                                    <span className="position-absolute"><i className="fa-solid fa-heart-circle-check"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card-health-body p-4">
                                <div className="bg-white position-relative">
                                    <div className="position-absolute h-100  p-3 d-flex flex-column " style={{zIndex: 50}}>
                                        <h1 className="fs-4">Health Fitness</h1>
                                        <p >
                                            This programs is designed
                                            for those who exercises only
                                            for their body fitness not body
                                            building.
                                        </p>
                                        <button className="btn btn-success mt-auto">Join Now</button>
                                    </div>
                                    <span className="position-absolute"><i className="fa-solid fa-heart-circle-check"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-4">
                            <div className="card-health-body p-4">
                                <div className="bg-white position-relative">
                                    <div className="position-absolute h-100  p-3 d-flex flex-column " style={{zIndex: 50}}>
                                        <h1 className="fs-4">Health Fitness</h1>
                                        <p >
                                            This programs is designed
                                            for those who exercises only
                                            for their body fitness not body
                                            building.
                                        </p>
                                        <button className="btn btn-success mt-auto">Join Now</button>
                                    </div>
                                    <span className="position-absolute"><i className="fa-solid fa-heart-circle-check"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Health;
